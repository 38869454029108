<template>
  <div>
    <b-row v-if="isValidToken && tokenChecked && canShow && this.isCompanyApproved !== -1">
      <b-col
        cols="12"
        lg="6"
      >
        <b-card
          v-if="invoice.isCompanyApproved !== 1 && invoiceType === 1"
          title="Non-dispuutverklaring"
        >
          <b-card-text>
            <p>U kunt hieronder kiezen om een non-dispuut verklaring af te geven op dit factuur of dit factuur af te wijzen</p>

            <b-button
              variant="outline-success"
              class="mr-1"
              @click="feedback(1)"
              :disabled="showFeedbackLoader"
            >
              <b-spinner
                  small
                  class="mr-1"
                  v-if="showFeedbackLoader"
              />
              <span class="align-middle">Accordeer</span>

            </b-button>
            <b-button
              variant="outline-danger"
              @click="feedback(-1)"
              :disabled="showFeedbackLoader"
            >
              <b-spinner
                  small
                  class="mr-1"
                  v-if="showFeedbackLoader"
              />
              <span class="align-middle">Afwijzen en betwisten</span>

            </b-button>
          </b-card-text>
        </b-card>
        <b-card
          v-if="invoice.openAmount > 0 || (invoiceType === 2 && invoice.status === 10)"
          title="Factuur betalen met iDeal"
        >
          <b-card-text>
            <p>Kies hieronder je bank en klik vervolgens op betaal met iDeal om dit factuur te voldoen.</p>
            <b-form-group
              label="Bank"
              label-for="issuer2"
            >
              <v-select
                id="issuer2"
                v-model="payment.issuer"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="idealIssuers"
                :clearable="false"
                class="mb-2 item-selector-title"
                placeholder="Selecteer uw bank"
              />
            </b-form-group>
            <b-button
              variant="outline-primary"
              style="float:right"
              :disabled="!payment.issuer"
              @click="payWithIdeal()"
            >
              <b-spinner
                v-if="showIdealLoader"
                small
                class="mr-1"
              />
              <svg
                v-if="!showIdealLoader"
                style="height: 22px!important;margin-top: -2px;margin-left: -12px;margin-right: 6px;"
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="ideal"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                class="svg-inline--fa fa-ideal fa-w-18 "
              ><path
                fill="currentColor"
                d="M125.61,165.48a49.07,49.07,0,1,0,49.06,49.06A49.08,49.08,0,0,0,125.61,165.48ZM86.15,425.84h78.94V285.32H86.15Zm151.46-211.6c0-20-10-22.53-18.74-22.53H204.82V237.5h14.05C228.62,237.5,237.61,234.69,237.61,214.24Zm201.69,46V168.93h22.75V237.5h33.69C486.5,113.08,388.61,86.19,299.67,86.19H204.84V169h14c25.6,0,41.5,17.35,41.5,45.26,0,28.81-15.52,46-41.5,46h-14V425.88h94.83c144.61,0,194.94-67.16,196.72-165.64Zm-109.75,0H273.3V169h54.43v22.73H296v10.58h30V225H296V237.5h33.51Zm74.66,0-5.16-17.67H369.31l-5.18,17.67H340.47L368,168.92h32.35l27.53,91.34ZM299.65,32H32V480H299.65c161.85,0,251-79.73,251-224.52C550.62,172,518,32,299.65,32Zm0,426.92H53.07V53.07H299.65c142.1,0,229.9,64.61,229.9,202.41C529.55,389.57,448.55,458.92,299.65,458.92Zm83.86-264.85L376,219.88H392.4l-7.52-25.81Z"
                class=""
              /></svg>
              <span class="align-middle">Betaal met iDeal</span>
            </b-button>
          </b-card-text>
        </b-card>
        <b-card
          title="Factuurinformatie"
        >
          <b-card-text>
            <div class="row">
              <div class="col-lg-6">
                <div
                  v-if="invoiceType === 1"
                  class="mt-1"
                >
                  <h6 class="mb-0">
                    Opdrachtnemer:
                  </h6>
                  <p>{{ invoice.freelancerName }}</p>
                </div>
                <div class="mt-1">
                  <h6 class="mb-0">
                    Opdrachtgever:
                  </h6>
                  <p>{{ invoice.companyName }}</p>
                </div>
                <div
                  v-if="invoiceType === 1"
                  class="mt-1"
                >
                  <h6 class="mb-0">
                    Overeenkomst:
                  </h6>
                  <p>Geen</p>
                </div>
                <div class="mt-1">
                  <h6 class="mb-0">
                    Factuurdatum:
                  </h6>
                  <p>{{ formatDate(invoice.invoiceDate) }}</p>
                </div>
                <div
                  v-if="invoiceType === 1"
                  class="mt-1"
                >
                  <h6 class="mb-0">
                    Vervaldatum:
                  </h6>
                  <p>{{ formatDate(invoice.expiryDate) }}</p>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="mt-1">
                  <h6 class="mb-0">
                    Bedrag:
                  </h6>
                  <p>€ {{ invoice.subAmount.toLocaleString( 'nl-NL' ,{ minimumFractionDigits: 2 } ) }}</p>
                </div>
                <div class="mt-1">
                  <h6 class="mb-0">
                    BTW ({{ invoice.vatRate }}%):
                  </h6>
                  <p>€ {{ invoice.vatAmount.toLocaleString( 'nl-NL' ,{ minimumFractionDigits: 2 } ) }}</p>
                </div>
                <div class="mt-1">
                  <h6 class="mb-0">
                    Totaal:
                  </h6>
                  <p>€ {{ invoice.totalAmount.toLocaleString( 'nl-NL' ,{ minimumFractionDigits: 2 } ) }}</p>
                </div>
                <hr>
                <div
                  v-if="invoiceType === 1"
                  class="mt-1"
                >
                  <h6 class="mb-0">
                    Openstaand:
                  </h6>
                  <p>€ {{ ((invoice.openAmount > 0.00) ? invoice.openAmount : 0).toLocaleString( 'nl-NL' ,{ minimumFractionDigits: 2 } ) }}</p>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        lg="6"
      >
        <b-card
          no-body
        >
          <b-card-header class="pb-3">
            <b-card-title>Factuur</b-card-title>
            <a :href="url">
              <feather-icon
                icon="DownloadIcon"
                size="18"
                class="cursor-pointer"
              /></a>
          </b-card-header>
          <b-card-body>
            <pdf
              v-if="url"
              ref="pdfViewer"
              :src="url"
              :page="page"
              dpi="350"
              @num-pages="pageCount = $event"
              @page-loaded="showOverlay = false"
              @error="error"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="isValidToken && tokenChecked && canShow && invoice.isCompanyApproved === -1">
      <b-col cols="12">
        <b-card
            title="Factuur betwist"
        >
          <b-card-text>
            Dit factuur is betwist door de opdrachtgever. Contacteer uw freelancer of PayRite voor meer informatie
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="isValidToken && tokenChecked && !canShow">
      <b-col cols="12">
        <b-card
            title="Factuur onder controle"
        >
          <b-card-text>
            Het betalen van dit factuur is nog niet mogelijk. Contacteer uw freelancer of PayRite voor meer informatie
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="!isValidToken && tokenChecked">
      <b-col cols="12">
        <b-card
          title="Invalide token"
        >
          <b-card-text>
            Deze link waarmee u deze pagina probeert te bezoeken is incorrect, deze is mogelijk verlopen. Contacteer uw freelancer of PayRite voor meer informatie
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BButton, BSpinner, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'
import store from '@/store'
import { formatDate } from '@core/utils/utils'
import { onUnmounted } from '@vue/composition-api'
import pdf from 'vue-pdf'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'
import vSelect from 'vue-select'
import payStoreModule from './payStoreModule'

export default {
  name: 'Invoice',
  components: {
    BRow,
    BCol,
    BCard,
    vSelect,
    // BCardText,
    BFormGroup,
    BButton,
    BSpinner,
    BCardHeader,
    BCardTitle,
    BCardBody,
    pdf,
  },
  data() {
    return {
      tokenChecked: false,
      isValidToken: false,
      showIdealLoader: false,
      showFeedbackLoader: false,
      canShow: false,
      token: '',
      invoice: {},
      payment: {},
      url: null,
      showOverlay: true,
      page: 1,
      pageCount: 0,
      invoiceType: 0,
    }
  },
  mounted() {
    this.token = this.$route.params.id
    store.dispatch('app-pay/fetchInvoice', {
      token: this.token,
    }).then(async response => {
      this.tokenChecked = true
      this.isValidToken = true
      if (response.data.isAdminApproved !== 0 && response.data.isFreelancerApproved !== 0) {
        this.canShow = true
      }
      this.invoice = response.data
      if (response.data.type === null || response.data.type !== 'InvoiceCollection') {
        this.invoiceType = 1
      } else {
        this.invoiceType = 2
      }
    })
      .catch(() => {
        this.tokenChecked = true
        this.isValidToken = false
      })

    this.url = `${axiosDefaultConfig.backendEndPoint}payment/${this.token}/pdf`
  },
  methods: {
    payWithIdeal() {
      this.showIdealLoader = true
      store.dispatch('app-pay/fetchIdealURL', {
        token: this.token,
        issuer: this.payment.issuer.value,
      }).then(async response => {
        if (response.data.success === true) {
          window.location.href = response.data.url
        } else {
          this.showIdealLoader = false
        }
      }).catch(() => {
        this.showIdealLoader = false
      })
    },
    feedback(fb) {
      this.showFeedbackLoader = true
      store.dispatch('app-pay/feedback', {
        token: this.token,
        feedback: fb,
      }).then(async response => {
        if (response) {
          store.dispatch('app-pay/fetchInvoice', {
            token: this.token,
          })
            .then(async resp => {
              this.invoice = resp.data
            })
        }
      }).catch(() => {
        this.showFeedbackLoader = false
      })
    },
    error() {
      this.showOverlay = false
    },
  },
  setup() {
    const PAY_APP_STORE_MODULE_NAME = 'app-pay'

    // Register module
    if (!store.hasModule(PAY_APP_STORE_MODULE_NAME)) store.registerModule(PAY_APP_STORE_MODULE_NAME, payStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAY_APP_STORE_MODULE_NAME)) store.unregisterModule(PAY_APP_STORE_MODULE_NAME)
    })
    const idealIssuers = [
      { label: 'ABN Amro', value: 'ideal_ABNANL2A' },
      { label: 'ING', value: 'ideal_INGBNL2A' },
      { label: 'Rabobank', value: 'ideal_RABONL2U' },
      { label: 'ASN Bank', value: 'ideal_ASNBNL21' },
      { label: 'bunq', value: 'ideal_BUNQNL2A' },
      { label: 'Handelsbanken', value: 'ideal_HANDNL2A' },
      { label: 'Knab', value: 'ideal_KNABNL2H' },
      { label: 'Regiobank', value: 'ideal_RBRBNL21' },
      { label: 'Revolut', value: 'ideal_REVOLT21' },
      { label: 'SNS Bank', value: 'ideal_SNSBNL2A' },
      { label: 'Triodos', value: 'ideal_TRIONL2U' },
      { label: 'Van Lanschot', value: 'ideal_FVLBNL22' },
    ]
    return { idealIssuers, formatDate }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
